import moment from "moment";

import i18next from "./i18next";

// allows for moment durations to be formatted
// import "moment-duration-format";

// MomentJS uses static language bundle loading, so in order to support dynamic
// language selection in the app we need to load all bundles that we support in
// the app.
import "moment/locale/af";
import "moment/locale/ar";
import "moment/locale/be";
import "moment/locale/bg";
import "moment/locale/ca";
import "moment/locale/cs";
import "moment/locale/da";
import "moment/locale/de";
import "moment/locale/el";
import "moment/locale/en-gb";
import "moment/locale/eo";
import "moment/locale/es-us";
import "moment/locale/es";
import "moment/locale/et";
import "moment/locale/eu";
import "moment/locale/fa";
import "moment/locale/fi";
import "moment/locale/fr-ca";
import "moment/locale/fr";
import "moment/locale/gl";
import "moment/locale/he";
import "moment/locale/hr";
import "moment/locale/hu";
import "moment/locale/hy-am";
import "moment/locale/id";
import "moment/locale/is";
import "moment/locale/it";
import "moment/locale/ja";
import "moment/locale/ko";
import "moment/locale/lt";
import "moment/locale/lv";
import "moment/locale/ml";
import "moment/locale/mn";
import "moment/locale/mr";
import "moment/locale/nb";
import "moment/locale/nl";
import "moment/locale/oc-lnc";
import "moment/locale/pl";
import "moment/locale/pt";
import "moment/locale/pt-br";
import "moment/locale/ro";
import "moment/locale/ru";
import "moment/locale/sk";
import "moment/locale/sl";
import "moment/locale/sr";
import "moment/locale/sv";
import "moment/locale/tr";
import "moment/locale/uk";
import "moment/locale/vi";
import "moment/locale/zh-cn";
import "moment/locale/zh-tw";

/**
 * Returns a localized date formatter initialized with a specific {@code Date}
 * or timestamp ({@code number}).
 *
 * @private
 * @param {Date | number} dateOrTimeStamp - The date or unix timestamp (ms)
 * to format.
 * @returns {Object}
 */
export function getLocalizedDateFormatter(dateOrTimeStamp: Date | number) {
  return moment(dateOrTimeStamp).locale(_getSupportedLocale());
}

/**
 * Returns a localized duration formatter initialized with a
 * specific duration ({@code number}).
 *
 * @private
 * @param {number} duration - The duration (ms)
 * to format.
 * @returns {Object}
 */
export function getLocalizedDurationFormatter(duration: number) {
  // FIXME The flow-type definition of moment is v2.3 while our package.json
  // states v2.19 so maybe locale on moment's duration was introduced in
  // between?
  //

  // If the conference is under an hour long we want to display it without
  // showing the hour and we want to include the hour if the conference is
  // more than an hour long
  try {
    // // @ts-ignore
    // if (moment.duration(duration).format("h") !== "0") {
    //   // @ts-ignore
    //   return moment.duration(duration).format("h:mm:ss");
    // }
    // // @ts-ignore
    // return moment.duration(duration).format("mm:ss", { trim: false });
    const dur = moment.duration(duration);

    const hours = dur.hours();
    const minutes = dur.minutes();
    const seconds = dur.seconds();
  
    if (hours !== 0) {
      return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }
  
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  } catch(err) {
      
  }
}

/**
 * A lenient locale matcher to match language and dialect if possible.
 *
 * @private
 * @returns {string}
 */
function _getSupportedLocale() {
  const i18nLocale = i18next.language;
  let supportedLocale;

  if (i18nLocale) {
    const localeRegexp = new RegExp("^([a-z]{2,2})(-)*([a-z]{2,2})*$");
    const localeResult = localeRegexp.exec(i18nLocale.toLowerCase());

    if (localeResult) {
      const currentLocaleRegexp = new RegExp(
        `^${localeResult[1]}(-)*${`(${localeResult[3]})*` || ""}`
      );

      supportedLocale =
        // FIXME The flow-type definition of moment is v2.3 while our
        // package.json states v2.19 so maybe locales on moment was
        // introduced in between?
        moment.locales().find((lang) => currentLocaleRegexp.exec(lang));
    }
  }

  return supportedLocale || "en";
}
