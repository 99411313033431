import React, { useEffect } from "react";
import { connect } from "react-redux";

import { IReduxState } from "../../../../app/types";
import Avatar from "../../../avatar/components/Avatar";
import Video from "../../../media/components/web/Video";
import { getLocalParticipant } from "../../../participants/functions";
import { getDisplayName } from "../../../settings/functions.web";
import { getLocalVideoTrack } from "../../../tracks/functions.web";

export interface IProps {
  /**
   * Local participant id.
   */
  _participantId: string;

  /**
   * Flag controlling whether the video should be flipped or not.
   */
  flipVideo: boolean;

  /**
   * The name of the user that is about to join.
   */
  name: string;

  /**
   * Flag signaling the visibility of camera preview.
   */
  videoMuted: boolean;

  /**
   * The JitsiLocalTrack to display.
   */
  videoTrack?: Object;
}

/**
 * Component showing the video preview and device status.
 *
 * @param {IProps} props - The props of the component.
 * @returns {ReactElement}
 */
function Preview(props: IProps) {
  const { _participantId, flipVideo, name, videoMuted, videoTrack } = props;
  const className = flipVideo ? "flipVideoX" : "";

  useEffect(() => {
    /**@ts-ignore*/
    APP.API.notifyPrejoinVideoVisibilityChanged(
      Boolean(!videoMuted && videoTrack)
    );
  }, [videoMuted, videoTrack]);

  useEffect(() => {
    /**@ts-ignore*/
    APP.API.notifyPrejoinLoaded();
    /**@ts-ignore*/
    return () => APP.API.notifyPrejoinVideoVisibilityChanged(false);
  }, []);

  return (
    <div id="preview">
      {!videoMuted && videoTrack ? (
        /**@ts-ignore*/
        <Video
          className={className}
          id="prejoinVideo"
          videoTrack={{ jitsiTrack: videoTrack }}
        />
      ) : (
        /**@ts-ignore*/
        <Avatar
          /**@ts-ignore*/
          className="premeeting-screen-avatar"
          /**@ts-ignore*/
          displayName={name}
          /**@ts-ignore*/
          participantId={_participantId}
          /**@ts-ignore*/
          size={200}
        />
      )}
    </div>
  );
}

/**
 * Maps part of the Redux state to the props of this component.
 *
 * @param {Object} state - The Redux state.
 * @param {IProps} ownProps - The own props of the component.
 * @returns {IProps}
 */
function _mapStateToProps(state: IReduxState, ownProps: any) {
  const name = getDisplayName(state);
  const { id: _participantId } = getLocalParticipant(state) ?? {};

  return {
    _participantId: _participantId ?? "",
    flipVideo: Boolean(state["features/base/settings"].localFlipX),
    name,
    videoMuted: ownProps.videoTrack
      ? ownProps.videoMuted
      : state["features/base/media"].video.muted,
    videoTrack:
      ownProps.videoTrack ||
      getLocalVideoTrack(state["features/base/tracks"])?.jitsiTrack,
  };
}
/**@ts-ignore*/
export default connect(_mapStateToProps)(Preview);
