import React from "react";
import "../app";

import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { App } from "./react/features/app/components/App.web";
// // import "./react/index.web";

const rootElement = document.getElementById("root")!;
const root = createRoot(rootElement);

root.render(
  //@ts-ignore
  <StrictMode>
    <App />
  </StrictMode>
);

// import "./react/index.web";
