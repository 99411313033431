//@ts-nocheck
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "tss-react/mui";

import { createToolbarEvent } from "../../../analytics/AnalyticsEvents";
import { sendAnalytics } from "../../../analytics/functions";
import Popover from "../../../base/popover/components/Popover.web";
import ContextMenu from "../../../base/ui/components/web/ContextMenu";
import ContextMenuItemGroup from "../../../base/ui/components/web/ContextMenuItemGroup";
import { setGifMenuVisibility } from "../../../gifs/actions";
import { isGifsMenuOpen } from "../../../gifs/functions.web";
import ReactionEmoji from "../../../reactions/components/web/ReactionEmoji";
import ReactionsMenu from "../../../reactions/components/web/ReactionsMenu";
import {
  GIFS_MENU_HEIGHT_IN_OVERFLOW_MENU,
  RAISE_HAND_ROW_HEIGHT,
  REACTIONS_MENU_HEIGHT_DRAWER,
  REACTIONS_MENU_HEIGHT_IN_OVERFLOW_MENU,
} from "../../../reactions/constants";
import { getReactionsQueue } from "../../../reactions/functions.any";
import { IReactionsMenuParent } from "../../../reactions/types";
import { DRAWER_MAX_HEIGHT } from "../../constants";
import { showOverflowDrawer } from "../../functions.web";

import Drawer from "./Drawer";
import JitsiPortal from "./JitsiPortal";
import OverflowToggleButton from "./OverflowToggleButton";
import { gotoBoard } from "../../../../../utils";

/**
 * The type of the React {@code Component} props of {@link OverflowMenuButton}.
 */
interface IProps {
  /**
   * ID of the menu that is controlled by this button.
   */
  ariaControls: string;

  /**
   * Information about the buttons that need to be rendered in the overflow menu.
   */
  buttons: Object[];

  /**
   * Whether or not the OverflowMenu popover should display.
   */
  isOpen: boolean;

  /**
   * Esc key handler.
   */
  onToolboxEscKey: (e?: React.KeyboardEvent) => void;

  /**
   * Callback to change the visibility of the overflow menu.
   */
  onVisibilityChange: Function;

  /**
   * Whether to show the raise hand in the reactions menu or not.
   */
  showRaiseHandInReactionsMenu: boolean;

  /**
   * Whether or not to display the reactions menu.
   */
  showReactionsMenu: boolean;
}

const useStyles = makeStyles<{
  overflowDrawer: boolean;
  reactionsMenuHeight: number;
}>()((_theme, { reactionsMenuHeight, overflowDrawer }) => {
  return {
    overflowMenuDrawer: {
      overflow: "hidden",
      height: `calc(${DRAWER_MAX_HEIGHT})`,
    },
    contextMenu: {
      position: "relative" as const,
      right: "auto",
      margin: 0,
      marginBottom: "8px",
      maxHeight: overflowDrawer ? undefined : "calc(100dvh - 100px)",
      paddingBottom: overflowDrawer ? undefined : 0,
      minWidth: "240px",
      overflow: "hidden",
    },
    content: {
      position: "relative",
      maxHeight: overflowDrawer
        ? `calc(100% - ${reactionsMenuHeight}px - 16px)`
        : `calc(100dvh - 100px - ${reactionsMenuHeight}px)`,
      overflowY: "auto",
    },
    footer: {
      position: "relative",
      bottom: 0,
    },
  };
});

const OverflowMenuButton = ({
  buttons,
  isOpen,
  onToolboxEscKey,
  onVisibilityChange,
  showRaiseHandInReactionsMenu,
  showReactionsMenu,
}: IProps) => {
  const overflowDrawer = useSelector(showOverflowDrawer);
  const reactionsQueue = useSelector(getReactionsQueue);
  const isGiphyVisible = useSelector(isGifsMenuOpen);
  const dispatch = useDispatch();

  const onCloseDialog = useCallback(() => {
    onVisibilityChange(false);
    if (isGiphyVisible && !overflowDrawer) {
      dispatch(setGifMenuVisibility(false));
    }
  }, [
    onVisibilityChange,
    setGifMenuVisibility,
    isGiphyVisible,
    overflowDrawer,
    dispatch,
  ]);

  const onOpenDialog = useCallback(() => {
    onVisibilityChange(true);
  }, [onVisibilityChange]);

  const onEscClick = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === "Escape" && isOpen) {
        event.preventDefault();
        event.stopPropagation();
        onCloseDialog();
      }
    },
    [onCloseDialog]
  );

  const toggleDialogVisibility = useCallback(() => {
    sendAnalytics(createToolbarEvent("overflow"));

    onVisibilityChange(!isOpen);
  }, [isOpen, onVisibilityChange]);

  const toolbarAccLabel = "toolbar.accessibilityLabel.moreActionsMenu";
  const { t } = useTranslation();
  let reactionsMenuHeight = 0;

  if (showReactionsMenu) {
    reactionsMenuHeight = REACTIONS_MENU_HEIGHT_DRAWER;
    if (!overflowDrawer) {
      reactionsMenuHeight = REACTIONS_MENU_HEIGHT_IN_OVERFLOW_MENU;
    }
    if (!showRaiseHandInReactionsMenu) {
      reactionsMenuHeight -= RAISE_HAND_ROW_HEIGHT;
    }
    if (!overflowDrawer && isGiphyVisible) {
      reactionsMenuHeight += GIFS_MENU_HEIGHT_IN_OVERFLOW_MENU;
    }
  }
  const { classes } = useStyles({
    reactionsMenuHeight,
    overflowDrawer,
  });

  const groupsJSX = buttons.map((buttonGroup: any) => (
    <ContextMenuItemGroup key={`group-${buttonGroup[0].group}`}>
      {/* <div onClick={gotoBoard}>
        <div class="css-es5z1v-contextMenuItemGroup">
          <div
            aria-disabled="false"
            aria-label="WhiteBoard"
            class="css-m5fnqo-contextMenuItem"
            role="button"
            tabindex="0"
          >
            <div class="jitsi-icon jitsi-icon-default css-1i0btwg-contextMenuItemIcon">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M21 3.75H3V14.25H21V3.75ZM3 2.25C2.17157 2.25 1.5 2.92157 1.5 3.75V14.25C1.5 15.0784 2.17157 15.75 3 15.75H21C21.8284 15.75 22.5 15.0784 22.5 14.25V3.75C22.5 2.92157 21.8284 2.25 21 2.25H3Z"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9.3046 14.3146C9.68312 14.4829 9.85359 14.9261 9.68536 15.3046L6.68536 22.0546C6.51713 22.4331 6.07391 22.6036 5.6954 22.4354C5.31688 22.2671 5.14641 21.8239 5.31464 21.4454L8.31464 14.6954C8.48287 14.3169 8.92609 14.1464 9.3046 14.3146Z"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M14.6954 14.3146C14.3169 14.4829 14.1464 14.9261 14.3146 15.3046L17.3146 22.0546C17.4829 22.4331 17.9261 22.6036 18.3046 22.4354C18.6831 22.2671 18.8536 21.8239 18.6854 21.4454L15.6854 14.6954C15.5171 14.3169 15.0739 14.1464 14.6954 14.3146Z"
                />
              </svg>
            </div>
            <div class="css-2f5p2-text-textContainer">
              <span class="css-1h7r6a1-ellipsis">WhiteBoard</span>
            </div>
          </div>
        </div>
      </div> */}
      {buttonGroup.map(
        ({
          key,
          Content,
          ...rest
        }: {
          Content: React.ElementType;
          key: string;
        }) => {
          const props: {
            buttonKey?: string;
            contextMenu?: boolean;
            showLabel?: boolean;
          } = { ...rest };

          if (key !== "reactions") {
            props.buttonKey = key;
            props.contextMenu = true;
            props.showLabel = true;
          }

          return <Content {...props} key={key} />;
        }
      )}
    </ContextMenuItemGroup>
  ));

  const overflowMenu = groupsJSX && (
    <ContextMenu
      accessibilityLabel={t(toolbarAccLabel)}
      className={classes.contextMenu}
      hidden={false}
      id="overflow-context-menu"
      inDrawer={overflowDrawer}
      onKeyDown={onToolboxEscKey}
    >
      <div className={classes.content}>{groupsJSX}</div>
      {showReactionsMenu && (
        <div className={classes.footer}>
          <ReactionsMenu
            parent={
              overflowDrawer
                ? IReactionsMenuParent.OverflowDrawer
                : IReactionsMenuParent.OverflowMenu
            }
            showRaisedHand={showRaiseHandInReactionsMenu}
          />
        </div>
      )}
    </ContextMenu>
  );

  if (overflowDrawer) {
    return (
      <div className="toolbox-button-wth-dialog context-menu">
        <>
          <OverflowToggleButton
            handleClick={toggleDialogVisibility}
            isOpen={isOpen}
            onKeyDown={onEscClick}
          />
          <JitsiPortal>
            <Drawer isOpen={isOpen} onClose={onCloseDialog}>
              <>
                <div className={classes.overflowMenuDrawer}>{overflowMenu}</div>
              </>
            </Drawer>
            {showReactionsMenu && (
              <div className="reactions-animations-container">
                {reactionsQueue.map(({ reaction, uid }, index) => (
                  <ReactionEmoji
                    index={index}
                    key={uid}
                    reaction={reaction}
                    uid={uid}
                  />
                ))}
              </div>
            )}
          </JitsiPortal>
        </>
      </div>
    );
  }

  return (
    <div className="toolbox-button-wth-dialog context-menu">
      <Popover
        content={overflowMenu}
        headingId="overflow-context-menu"
        onPopoverClose={onCloseDialog}
        onPopoverOpen={onOpenDialog}
        position="top"
        trigger="click"
        visible={isOpen}
      >
        <OverflowToggleButton
          isMenuButton={true}
          isOpen={isOpen}
          onKeyDown={onEscClick}
        />
      </Popover>
      {showReactionsMenu && (
        <div className="reactions-animations-container">
          {reactionsQueue.map(({ reaction, uid }, index) => (
            <ReactionEmoji
              index={index}
              key={uid}
              reaction={reaction}
              uid={uid}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default OverflowMenuButton;
