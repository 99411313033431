import { ReactComponent as IconRecordAccount } from "./account-record.svg";
import { ReactComponent as IconAddUser } from "./add-user.svg";
import { ReactComponent as IconArrowBack } from "./arrow-back.svg";
import { ReactComponent as IconArrowDownLarge } from "./arrow-down-large.svg";
import { ReactComponent as IconArrowDown } from "./arrow-down.svg";
import { ReactComponent as IconArrowLeft } from "./arrow-left.svg";
import { ReactComponent as IconArrowRight } from "./arrow-right.svg";
import { ReactComponent as IconArrowUpLarge } from "./arrow-up-large.svg";
import { ReactComponent as IconArrowUp } from "./arrow-up.svg";
import { ReactComponent as IconBell } from "./bell.svg";
import { ReactComponent as IconBluetooth } from "./bluetooth.svg";
import { ReactComponent as IconCalendar } from "./calendar.svg";
import { ReactComponent as IconCameraRefresh } from "./camera-refresh.svg";
import { ReactComponent as IconCar } from "./car.svg";
import { ReactComponent as IconChatUnread } from "./chat-unread.svg";
import { ReactComponent as IconCheck } from "./check.svg";
import { ReactComponent as IconCloseCircle } from "./close-circle.svg";
import { ReactComponent as IconCloseLarge } from "./close-large.svg";
import { ReactComponent as IconCloudUpload } from "./cloud-upload.svg";
import { ReactComponent as IconCode } from "./code.svg";
import { ReactComponent as IconConnection } from "./connection.svg";
import { ReactComponent as IconRecordContact } from "./contact-record.svg";
import { ReactComponent as IconCopy } from "./copy.svg";
import { ReactComponent as IconDotsHorizontal } from "./dots-horizontal.svg";
import { ReactComponent as IconDownload } from "./download.svg";
import { ReactComponent as IconE2EE } from "./e2ee.svg";
import { ReactComponent as IconEdit } from "./edit.svg";
import { ReactComponent as IconEmotionsAngry } from "./emotions-angry.svg";
import { ReactComponent as IconEmotionsDisgusted } from "./emotions-disgusted.svg";
import { ReactComponent as IconEmotionsFearful } from "./emotions-fearful.svg";
import { ReactComponent as IconEmotionsHappy } from "./emotions-happy.svg";
import { ReactComponent as IconEmotionsNeutral } from "./emotions-neutral.svg";
import { ReactComponent as IconEmotionsSad } from "./emotions-sad.svg";
import { ReactComponent as IconEmotionsSurprised } from "./emotions-surprised.svg";
import { ReactComponent as IconEnlarge } from "./enlarge.svg";
import { ReactComponent as IconEnterFullscreen } from "./enter-fullscreen.svg";
import { ReactComponent as IconEnvelope } from "./envelope.svg";
import { ReactComponent as IconExclamationSolid } from "./exclamation-solid.svg";
import { ReactComponent as IconExclamationTriangle } from "./exclamation-triangle.svg";
import { ReactComponent as IconExitFullscreen } from "./exit-fullscreen.svg";
import { ReactComponent as IconFaceSmile } from "./face-smile.svg";
import { ReactComponent as IconFavoriteSolid } from "./favorite-solid.svg";
import { ReactComponent as IconFavorite } from "./favorite.svg";
import { ReactComponent as IconFeedback } from "./feedback.svg";
import { ReactComponent as IconGear } from "./gear.svg";
import { ReactComponent as IconGoogle } from "./google.svg";
import { ReactComponent as IconHangup } from "./hangup.svg";
import { ReactComponent as IconDeviceHeadphone } from "./headset.svg";
import { ReactComponent as IconHelp } from "./help.svg";
import { ReactComponent as IconHighlight } from "./highlight.svg";
import { ReactComponent as IconRingGroup } from "./icon-ring-group.svg";
import { ReactComponent as IconImage } from "./image.svg";
import { ReactComponent as IconInfoCircle } from "./info-circle.svg";
import { ReactComponent as IconInfo } from "./info.svg";
import { ReactComponent as IconRecordLead } from "./lead-record.svg";
import { ReactComponent as IconMessage } from "./message.svg";
import { ReactComponent as IconMeter } from "./meter.svg";
import { ReactComponent as IconMicSlash } from "./mic-slash.svg";
import { ReactComponent as IconMic } from "./mic.svg";
import { ReactComponent as IconModerator } from "./moderator.svg";
import { ReactComponent as IconConnectionInactive } from "./ninja.svg";
import { ReactComponent as IconNoiseSuppressionOff } from "./noise-suppression-off.svg";
import { ReactComponent as IconNoiseSuppressionOn } from "./noise-suppression-on.svg";
import { ReactComponent as IconOffice365 } from "./office365.svg";
import { ReactComponent as IconRecordOpportunity } from "./opportunity-record.svg";
import { ReactComponent as IconPerformance } from "./performance.svg";
import { ReactComponent as IconPhoneRinging } from "./phone-ringing.svg";
import { ReactComponent as IconPin } from "./pin.svg";
import { ReactComponent as IconPinned } from "./pinned.svg";
import { ReactComponent as IconPlay } from "./play.svg";
import { ReactComponent as IconPlus } from "./plus.svg";
import { ReactComponent as IconRaiseHand } from "./raise-hand.svg";
import { ReactComponent as IconRecord } from "./record.svg";
import { ReactComponent as IconReply } from "./reply.svg";
import { ReactComponent as IconRestore } from "./restore.svg";
import { ReactComponent as IconScreenshare } from "./screenshare.svg";
import { ReactComponent as IconSearch } from "./search.svg";
import { ReactComponent as IconSecurityOff } from "./security-off.svg";
import { ReactComponent as IconSecurityOn } from "./security-on.svg";
import { ReactComponent as IconSend } from "./send.svg";
import { ReactComponent as IconShareDoc } from "./share-doc.svg";
import { ReactComponent as IconShare } from "./share.svg";
import { ReactComponent as IconShortcuts } from "./shortcuts.svg";
import { ReactComponent as IconSip } from "./sip.svg";
import { ReactComponent as IconSites } from "./sites.svg";
import { ReactComponent as IconRemoteControlStart } from "./start-remote-control.svg";
import { ReactComponent as IconRemoteControlStop } from "./stop-remote-control.svg";
import { ReactComponent as IconStop } from "./stop.svg";
import { ReactComponent as IconSubtitles } from "./subtitles.svg";
import { ReactComponent as IconTileView } from "./tile-view.svg";
import { ReactComponent as IconTrash } from "./trash.svg";
import { ReactComponent as IconUserDeleted } from "./user-deleted.svg";
import { ReactComponent as IconUser } from "./user.svg";
import { ReactComponent as IconUsers } from "./users.svg";
import { ReactComponent as IconVideoOff } from "./video-off.svg";
import { ReactComponent as IconVideo } from "./video.svg";
import { ReactComponent as IconAudioOnlyOff } from "./visibility-off.svg";
import { ReactComponent as IconAudioOnly } from "./visibility.svg";
import { ReactComponent as IconVolumeOff } from "./volume-off.svg";
import { ReactComponent as IconVolumeUp } from "./volume-up.svg";
import { ReactComponent as IconWarningCircle } from "./warning-circle.svg";
import { ReactComponent as IconWarning } from "./warning.svg";
import { ReactComponent as IconWhiteBoard } from "./whiteboard.svg";

import { ReactComponent as IconWifi1Bar } from "./wifi-1.svg";
import { ReactComponent as IconWifi2Bars } from "./wifi-2.svg";
import { ReactComponent as IconWifi3Bars } from "./wifi-3.svg";
import { ReactComponent as IconYahoo } from "./yahoo.svg";

/**
 * Map containing the default icons.
 */
export const DEFAULT_ICON = {
  IconAddUser,
  IconArrowBack,
  IconArrowDown,
  IconArrowDownLarge,
  IconArrowLeft,
  IconArrowUp,
  IconArrowUpLarge,
  IconAudioOnly,
  IconAudioOnlyOff,
  IconBluetooth,
  IconBell,
  IconCalendar,
  IconCameraRefresh,
  IconCar,
  IconChatUnread,
  IconCheck,
  IconCloseCircle,
  IconCloseLarge,
  IconCloudUpload,
  IconCode,
  IconConnection,
  IconConnectionInactive,
  IconCopy,
  IconDeviceHeadphone,
  IconDotsHorizontal,
  IconDownload,
  IconE2EE,
  IconEdit,
  IconEnlarge,
  IconEnterFullscreen,
  IconEnvelope,
  IconEmotionsAngry,
  IconEmotionsDisgusted,
  IconEmotionsFearful,
  IconEmotionsHappy,
  IconEmotionsNeutral,
  IconEmotionsSad,
  IconEmotionsSurprised,
  IconExclamationSolid,
  IconExclamationTriangle,
  IconExitFullscreen,
  IconFaceSmile,
  IconFavorite,
  IconFavoriteSolid,
  IconFeedback,
  IconGear,
  IconGoogle,
  IconHangup,
  IconHelp,
  IconHighlight,
  IconImage,
  IconInfo,
  IconInfoCircle,
  IconMessage,
  IconMeter,
  IconMic,
  IconMicSlash,
  IconModerator,
  IconNoiseSuppressionOff,
  IconNoiseSuppressionOn,
  IconArrowRight,
  IconOffice365,
  IconPerformance,
  IconPhoneRinging,
  IconPin,
  IconPinned,
  IconPlay,
  IconPlus,
  IconRaiseHand,
  IconRecord,
  IconRecordAccount,
  IconRecordContact,
  IconRecordLead,
  IconRecordOpportunity,
  IconRemoteControlStart,
  IconRemoteControlStop,
  IconReply,
  IconRestore,
  IconRingGroup,
  IconScreenshare,
  IconSearch,
  IconSecurityOff,
  IconSecurityOn,
  IconSend,
  IconShare,
  IconShareDoc,
  IconShortcuts,
  IconSip,
  IconSites,
  IconStop,
  IconSubtitles,
  IconTileView,
  IconTrash,
  IconUserDeleted,
  IconUsers,
  IconUser,
  IconVideo,
  IconVideoOff,
  IconVolumeOff,
  IconVolumeUp,
  IconWarning,
  IconWarningCircle,
  IconWifi1Bar,
  IconWifi2Bars,
  IconWifi3Bars,
  IconYahoo,
  IconWhiteBoard
};
