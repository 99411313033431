//@ts-nocheck
import { connect } from "react-redux";

import { IReduxState } from "../../../app/types";
import { translate } from "../../../base/i18n/functions";
import { IconWhiteBoard } from "../../../base/icons/svg";
import { MEDIA_TYPE } from "../../../base/media/constants";
import AbstractButton, {
  IProps as AbstractButtonProps,
} from "../../../base/toolbox/components/AbstractButton";
import { toggleCamera } from "../../../base/tracks/actions.web";
import {
  isLocalTrackMuted,
  isToggleCameraEnabled,
} from "../../../base/tracks/functions.web";
import { setOverflowMenuVisible } from "../../actions.web";
import { isIosMobileBrowser } from "../../../base/environment/utils";
import { gotoBoard } from "../../../../../utils";
/**
 * The type of the React {@code Component} props of {@link ToggleCameraButton}.
 */
interface IProps extends AbstractButtonProps {
  /**
   * Whether the current conference is in audio only mode or not.
   */
  _audioOnly: boolean;

  /**
   * Whether video is currently muted or not.
   */
  _videoMuted: boolean;
}

/**
 * An implementation of a button for toggling the camera facing mode.
 */
class WhiteBoardButton extends AbstractButton<IProps> {
  tooltip = "whiteboard.accessibilityLabel.heading";
  icon = IconWhiteBoard;
  label = "whiteboard.accessibilityLabel.heading";

  /**
   * Handles clicking/pressing the button.
   *
   * @returns {void}
   */
  _handleClick() {
    gotoBoard();
  }

  /**
   * Whether this button is disabled or not.
   *
   * @returns {boolean}
   */
  _isDisabled() {
    return this.props._audioOnly || this.props._videoMuted;
  }
}

/**
 * Maps (parts of) the redux state to the associated props for the
 * {@code ToggleCameraButton} component.
 *
 * @param {Object} state - The Redux state.
 * @returns {IProps}
 */
function mapStateToProps(state: IReduxState) {
  const { enabled: audioOnly } = state["features/base/audio-only"];
  const tracks = state["features/base/tracks"];

  return {
    visible: !isIosMobileBrowser(),
  };
}

export default translate(connect(mapStateToProps)(WhiteBoardButton));
