export function getQueryString(name: string) {
  const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  const r = window.location.search.substr(1).match(reg);
  if (r != null) {
    return decodeURIComponent(r[2]);
  }
  return "";
}

export const gotoBoard = () => {
  const userId = getQueryString("aid"); //localStorage.getItem("aid");
  const lessonId = getQueryString("lessonId");
  const nickName = getQueryString("nickName");
  //@ts-ignore
  const boardUrl = import.meta.env.VITE_APP_BOARD_URL;
  let url = `${boardUrl}/?roomId=${lessonId}&userId=${userId}&nickName=${nickName}`;
  url += `/#room=${lessonId},113ggQNGuTbfV57L3OeR0Q`;
  window.open(url);
};
